<template>
  <div class="fragment">

    <UnidentifiedPaymentsTableAdmin
            v-if="$store.getters.getCurrentUserRights.additionalPermissions &&
                $store.getters.getCurrentUserRights.additionalPermissions[PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX]"

            :navTabs="navTabs"
            @changeTab="(tab) => changeTab(tab, 'navTabs')"
            :countFilterParams="countFilterParams"
            :filterGetParams="filterGetParams"
            :listPeriod="listPeriod"
            @changePeriod="changePeriod"
            @changeFilter="changeFilter"
            @showMore="showMore"
            @downloadFiles="downloadFiles"
            @resetFilter="resetFilter"
    />
    <UnidentifiedPaymentsTableUser
            v-if="$store.getters.getCurrentUserRights.additionalPermissions &&
                !$store.getters.getCurrentUserRights.additionalPermissions[PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX]"

            :navTabs="navTabs"
            @changeTab="(tab) => changeTab(tab, 'navTabs')"
            :countFilterParams="countFilterParams"
            :filterGetParams="filterGetParams"
            :listPeriod="listPeriod"
            @changePeriod="changePeriod"
            @changeFilter="changeFilter"
            @showMore="showMore"
            @downloadFiles="downloadFiles"
            @resetFilter="resetFilter"
    />
  </div>
</template>

<script>
  import UnidentifiedPaymentsTableAdmin from "./UnidentifiedPaymentsTableAdmin/UnidentifiedPaymentsTableAdmin";
  import UnidentifiedPaymentsTableUser from "./UnidentifiedPaymentsTableUser/UnidentifiedPaymentsTableUser";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {radioButtonsFunc} from "../../../../../mixins/creationPageMixins/radioButtonsFunc";

  export default {
    name: "UnidentifiedPaymentsTable",
    components: {
      UnidentifiedPaymentsTableAdmin,
      UnidentifiedPaymentsTableUser,
    },

    mixins: [routeFilter, radioButtonsFunc],

    watch: {
      downloadPermissions() {
        this.checkTabPermission()
      },
    },

    data() {
      return {
        forPage: this.$store.getters.getUnidentifiedPaymentsForPage,
        page: 1,
        onePage: 1,
        filterType: 'paypal',
        filterDate: [],
        filterTrackingNumber: '',
        filterTotal: '',
        filterBuyersEmail: '',
        filterInvoiceNumber: '',
        filterItems: '',
        filterId: '',
        filterUser: '',
        filterUserId: '',
        filterPeriod: 1,
        filterPriceRange: [],

        countFilterParams: 0,
        filterGetParams: {},

        navTabs: {
          paypal: {
            name: 'PayPal',
            value: 'paypal',
            id: 1,
            active: true,
            permissionVisible: false,
          },
          authorize: {
            name: 'Authorize.net',
            value: 'authorize',
            id: 2,
            active: false,
            permissionVisible: false,
          },
          braintree: {
            name: 'Braintree',
            value: 'braintree',
            id: 4,
            active: false,
            permissionVisible: false,
          },
          archive: {
            name: 'Archived',
            label: this.$t('unidentifiedP_archived.localization_value.value'),
            value: 'archive',
            id: 3,
            active: false,
            permissionVisible: false,
          },
          // unassigned: {
          //   name: this.$t('unidentifiedP_unassigned.localization_value.value'),
          //   value: 'unassigned',
          //   id: 2,
          //   active: false
          // },
        },

        listPeriod: [
          this.$t('unidentifiedP_all.localization_value.value'),
          3,
          7,
          15,
          30,
          60
        ],
      }
    },

    created() {
      if(this.downloadPermissions){
        this.checkTabPermission()
      }
    },

    methods: {

      checkTabPermission() {
        if(this.$route.query.type !== undefined){
          this.navTabs.paypal.active = false
          this.navTabs[this.$route.query.type].active = true
        }

        this.activeTabByPermissions('navTabs', {
          [this.navTabs.paypal.value]: this.PERMISSIONS.UNDEFINED_PAYMENT_PAYPAL,
          [this.navTabs.authorize.value]: this.PERMISSIONS.UNDEFINED_PAYMENT_AUTHORIZE,
          [this.navTabs.braintree.value]: this.PERMISSIONS.UNDEFINED_PAYMENT_BRAINTREE,
          [this.navTabs.archive.value]: this.PERMISSIONS.UNDEFINED_PAYMENT_ARCHIVED,
        })

        if(!this._.find(this.navTabs, {active: true}).permissionVisible){
          this.setActivePermissionVisibleTab('navTabs')
          return
        }

        this.filter()
      },


      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          date: this.$route.query.date,
          type: this.$route.query.type,
          id: this.$route.query.id,
          trackingNumber: this.$route.query.trackingNumber,
          total: this.$route.query.total,
          buyersEmail: this.$route.query.buyersEmail,
          invoiceNumber: this.$route.query.invoiceNumber,
          items: this.$route.query.items,
          user: this.$route.query.user,
          userId: this.$route.query.userId,
          period: this.$route.query.period,
          priceRange: this.$route.query.priceRange,
        }
      },

      changePeriod(val) {
        this.changeFilter({period: val})
      },

      filter(next = false) {
        this.getFilterParam()

        let forPage, page, url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(page, forPage)

        // True if get next page
        if (next) this.$store.commit('setNextUnidentifiedPaymentsPage', true)

        // save filter params in store
        this.$store.commit('setUnidentifiedPaymentsFilter', window.location.search)

        let type = !this.$store.getters.getCurrentUserRights.additionalPermissions[this.PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX] ?
          '' :
          '/' + (this.filterType !== '' ? this.filterType : this.navTabs.paypal.value)

        this.$store.dispatch('fetchUnidentifiedPayments', {type: type, filter: url}).then(() => {
          // skip get next page
          this.$store.commit('setNextUnidentifiedPaymentsPage', false)
        })


        this.checkCountFilter(['user', 'type', 'period'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        if (this.filterTrackingNumber !== '') myQuery.where('PaymentTransactionId', this.filterTrackingNumber)
        if (this.filterTotal !== '') myQuery.where('PaymentTransactionPaymentTotal', this.filterTotal)
        if (this.filterId !== '') myQuery.where('PaymentId', this.filterId)
        if (this.filterPeriod !== '' && this.filterPeriod !== '1'){
          myQuery.where('period_day', this.listPeriod[this.filterPeriod - 1])
          forPage = 1000000
        }
        if (this.filterBuyersEmail !== '') myQuery.where('buyer_email', this.filterBuyersEmail)
        if (this.filterInvoiceNumber !== '') myQuery.where('PaymentTransactionDetailLNumber0', this.filterInvoiceNumber)
        if (this.filterItems !== '') myQuery.where('items', this.filterItems)


        this.filterDate = this.$route.query.date !== undefined ? this.$route.query.date : ''
        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn(
            'undefined_between_created_at',
            date
          )
        }

        if (this.filterPriceRange.length > 0) {
          myQuery.where(
              'paymentTotalBetween',
              this.filterPriceRange
          )
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      downloadFiles(fileType) {
        let forPage, page, url, exportIds

        exportIds = this.getCheckedRows('row-name')

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(false, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        url = this.generateFilterUrl(page, forPage, exportIds)

        let type = !this.$store.getters.getCurrentUserRights.additionalPermissions[this.PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX] ?
          '' :
          '/' + (this.filterType !== '' ? this.filterType : this.navTabs.paypal.value)

        this.$store.dispatch('getExportUnidentifiedPayments', {systemType: type, filter: url , type: fileType}).then((response) => {
          const blob = new Blob([response.data], { type: `application/${fileType}` })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `Unidentified Payments.${fileType}`
          link.click()
          URL.revokeObjectURL(link.href)
        })

      },

    },

  }
</script>

<style scoped>

</style>
