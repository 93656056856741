<template>
  <div class="fragment">
    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--separator">

      </div>
      <div class="content-top-line-wrap">
        <ToggleFilterButton  :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>
    <!--<div class="light-bg-block">-->
      <!--<div class="btn-left-block"-->
           <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--<span class="admin-edit"-->
              <!--@click="editTranslate(['unidentifiedP_periodOfDays', 'unidentifiedP_all'])"></span>-->
        <!--<PeriodOfDaysBlock-->
                <!--:title="$t('unidentifiedP_periodOfDays.localization_value.value')"-->
                <!--:list="listPeriod"-->
                <!--:active="activePeriod"-->
                <!--@changePeriod="changePeriod"-->
        <!--/>-->
      <!--</div>-->
    <!--</div>-->
  </div>
</template>

<script>

  // import PeriodOfDaysBlock from "../../../../../../coreComponents/PeriodOfDaysBlock/PeriodOfDaysBlock";

  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";

  export default {
    name: "UnidentifiedPaymentsHead",
    components: {
      // PeriodOfDaysBlock,
      ToggleFilterButton,
    },

    props: [
      'countFilterParams',
      'listPeriod',
      'filterGetParams',
      'navTabs',
      'showFilter',
    ],

    watch: {
      filterGetParams(newVal) {
        this.activePeriod = newVal.period ? newVal.period : 1
      }
    },

    data(){
      return{
        activePeriod: this.filterGetParams.period ? this.filterGetParams.period : 1,
      }
    },

    methods: {
      changePeriod(val){
        this.$emit('changePeriod', val)
      },
    }
  }
</script>

<style scoped>

</style>
