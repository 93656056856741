<template>
  <div class="fragment">

    <div class="content-top-line content-top-line--with-bottom-line"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['unidentifiedP_unassigned', 'unidentifiedP_archived'])"></span>
      <div class="content-tabs content-tabs--separator"
           v-bind:class="{'loading-tabs': $store.getters.getUnidentifiedPaymentsLoading === true,}">
        <div class="content-tabs__item"
             v-if="navTabs.paypal.permissionVisible"
             v-bind:class="{active: navTabs.paypal.active}"
             @click="changeTab(navTabs.paypal.value)"
        >
          <div class="content-tabs__ico">
            {{ navTabs.paypal.name }}
          </div>
        </div>
        <div class="content-tabs__item"
             v-if="navTabs.authorize.permissionVisible"
             v-bind:class="{active: navTabs.authorize.active}"
             @click="changeTab(navTabs.authorize.value)"
        >
          <div class="content-tabs__ico">
            {{ navTabs.authorize.name }}
          </div>
        </div>
        <div class="content-tabs__item"
             v-if="navTabs.braintree.permissionVisible"
             v-bind:class="{active: navTabs.braintree.active}"
             @click="changeTab(navTabs.braintree.value)"
        >
          <div class="content-tabs__ico">
            {{ navTabs.braintree.name }}
          </div>
        </div>
        <div class="content-tabs__item"
             v-if="navTabs.archive.permissionVisible"
             v-bind:class="{active: navTabs.archive.active}"
             @click="changeTab(navTabs.archive.value)"
        >
          <div class="content-tabs__ico">
            {{ navTabs.archive.label }}
          </div>
        </div>
        <!--<div class="content-tabs__item " v-bind:class="{active: navTabs.unassigned.active}" @click="changeTab(navTabs.unassigned.value)">-->
          <!--{{ navTabs.unassigned.name }}-->
        <!--</div>-->
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton  :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <!--<div class="content-top-line"></div>-->
    <!--<div class="light-bg-block">-->
      <!--<div class="btn-left-block"-->
           <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--<span class="admin-edit"-->
              <!--@click="editTranslate(['unidentifiedP_periodOfDays', 'unidentifiedP_all'])"></span>-->
        <!--<PeriodOfDaysBlock-->
          <!--:title="$t('unidentifiedP_periodOfDays.localization_value.value')"-->
          <!--:list="listPeriod"-->
          <!--:active="activePeriod"-->
          <!--@changePeriod="changePeriod"-->
        <!--/>-->
      <!--</div>-->
    <!--</div>-->
  </div>
</template>

<script>

  // import PeriodOfDaysBlock from "../../../../../../coreComponents/PeriodOfDaysBlock/PeriodOfDaysBlock";
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";

  export default {
    name: "UnidentifiedPaymentsHead",
    components: {
      // PeriodOfDaysBlock,
      ToggleFilterButton,
    },

    props: [
      'showFilter',
      'countFilterParams',
      'navTabs',
      'listPeriod',
      'filterGetParams',
    ],

    watch: {
      filterGetParams(newVal) {
        this.activePeriod = newVal.period ? newVal.period : 1
      }
    },

    data(){
      return{
        activePeriod: this.filterGetParams.period ? this.filterGetParams.period : 1,

        searchField1: '',
        searchField2: '',
        searchField3: '',
        searchField4: '',


        isShowSearchEngineBlock: false,
      }
    },


    methods: {
      changePeriod(val){
        this.$emit('changePeriod', val)
      },

      showSearchEngineBlock(){
        this.isShowSearchEngineBlock = !this.isShowSearchEngineBlock;
      },

      changeTab(id) {
        this.$emit('changeTab', id)
      },
    }
  }
</script>

<style scoped>

</style>
