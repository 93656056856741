<template>
  <modal
      @close="$emit('close')"
      class="transaction-info-modal transaction-info custom-popup w470"
  >
    <template slot="header">
      <template v-if="payment = $store.getters.getUnidentifiedPaymentsItem">
        <span v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate([
            'cuspayments_transactions',
            'cuspayments_orderDetails',
            'cuspayments_trackingNumber',
            'cuspayments_transactions',
            'cuspayments_totalAmount',
            'cuspayments_total',
            'cuspayments_paypalFee',
            'cuspayments_SKLADUSAFee',
            'cuspayments_tax',
          ])"></span>
          {{$t('cuspayments_transactions.localization_value.value')}}:
          {{payment.transaction_id}}
        </span>
      </template>
    </template>
    <template slot="body">
      <div class="transaction-info__content" v-if="payment = $store.getters.getUnidentifiedPaymentsItem">
        <!--<div class="transaction-info__name">-->
          <!--<ValueHelper-->
                  <!--:value="payment.payment_transaction_detail.payment_transaction_contact.full_name"/>-->
        <!--</div>-->
        <div class="transaction-info__status">
          <div class="transaction-info__status-txt font-weight-normal"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate([
            'common_Paid',
            'status_Returned',
            'common_InProgress',
            'common_Rejected',
            'common_statusNew',
          ])"></span>
            <div class="d-flex"
                 v-if="payment.status.toLowerCase() === CUSTOMERS_PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS">
              <StatusIcoBtn :type="'active'" class="mr-2 t-0"/>
              {{$t('common_Paid.localization_value.value')}}
            </div>
            <div class="d-flex"
                 v-if="payment.status.toLowerCase() === CUSTOMERS_PAYMENTS_STATUSES.REFUND_TRANSACTION_STATUS">
              <StatusIcoBtn :type="'active'" class="mr-2 t-0"/>
              {{$t('status_Returned.localization_value.value')}}
            </div>
            <div class="d-flex"
                 v-if="payment.status.toLowerCase() === CUSTOMERS_PAYMENTS_STATUSES.IN_PROGRESS_TRANSACTION_STATUS">
              <StatusIcoBtn :type="'dots'" class="mr-2 t-0"/>
              {{$t('common_InProgress.localization_value.value')}}
            </div>
            <div class="d-flex"
                 v-if="payment.status.toLowerCase() === CUSTOMERS_PAYMENTS_STATUSES.CANCELED_TRANSACTION_STATUS ||
                          payment.status === CUSTOMERS_PAYMENTS_STATUSES.ERROR_STATUS">
              <StatusIcoBtn :type="'rejected'" class="mr-2 t-0"/>
              {{$t('common_Rejected.localization_value.value')}}
            </div>
            <div class="d-flex"
                 v-if="payment.status.toLowerCase() === CUSTOMERS_PAYMENTS_STATUSES.ON_HOLD_STATUS">
              <StatusIcoBtn :type="'star'" class="mr-2 t-0"/>
              {{$t('common_statusNew.localization_value.value')}}
            </div>
          </div>
        </div>

        <div class="transaction-info__section">
          <div class="transaction-info__section-title">
            {{$t('cuspayments_orderDetails.localization_value.value')}}
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                Transaction Date
              </div>
              <div class="transaction-info__txt">
                {{ payment.payment_transaction_detail.created_at | moment("DD MMM, YYYY") }} {{$t('common_at.localization_value.value')}}
                {{ payment.payment_transaction_detail.created_at | moment("HH:mm") }}
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('cuspayments_name.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="payment.payment_transaction_detail.payment_transaction_contact.full_name"/>
              </div>
            </div>
          </div>

        </div>

        <div class="transaction-info__section">
          <div class="transaction-info__section-title">
            {{$t('cuspayments_transactions.localization_value.value')}}
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('cuspayments_totalAmount.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                $
                <ValueHelper
                        :value="payment.payment_total"/>
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('cuspayments_SKLADUSAFee.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                $
                <ValueHelper
                        :value="payment.warehouse_commission_amount"/>
              </div>
            </div>

            <div class="transaction-info__col">
              <div class="transaction-info__label">
                <template v-if="payment.payment_system === 'braintree'">
                  {{$t('cuspayments_baintreeFee.localization_value.value')}}
                </template>
                <template v-else>
                  {{$t('cuspayments_paypalFee.localization_value.value')}}
                </template>
              </div>
              <div class="transaction-info__txt">
                $
                <ValueHelper
                        :value="payment.payment_commission"/>
              </div>
            </div>

            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('cuspayments_total.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                $
                <ValueHelper
                        :value="payment.user_amount"/>
              </div>
            </div>

            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('cuspayments_tax.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                $
                <ValueHelper
                        :value="payment.payment_tax"/>
              </div>
            </div>


          </div>
        </div>



      </div>
    </template>
    <!--<template slot="footer">-->
      <!--<div class="mt-5">-->
        <!--<router-link :to="'#'">-->
          <!--<MainButton-->
              <!--:value="'Request a Refund'"-->
              <!--class="transaction-info__btn-i"-->
          <!--/>-->
        <!--</router-link>-->
      <!--</div>-->
    <!--</template>-->
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  // import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
  import ValueHelper from "../../../../coreComponents/ValueHelper/ValueHelper";
  import StatusIcoBtn from "../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import {CUSTOMERS_PAYMENTS_STATUSES} from "../../../../../staticData/staticVariables";


  export default {
    name: "TransactionInfo",
    components: {
      StatusIcoBtn,
      Modal,
      // MainButton,
      ValueHelper,
    },

    data() {
      return {
        CUSTOMERS_PAYMENTS_STATUSES: CUSTOMERS_PAYMENTS_STATUSES,
      }
    },
  }

</script>

<style scoped lang="scss">
  .transaction-info__btn-i{
    font-size: 14px!important;
    line-height: 16px;
    width: fit-content;
    min-width: 144px;
  }
</style>
