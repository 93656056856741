<template>
  <div class="fragment">
    <UnidentifiedPaymentsHead
            :countFilterParams="countFilterParams"
            :listPeriod="listPeriod"
            :filterGetParams="filterGetParams"
            @changePeriod="(val) => $emit('changePeriod', val)"
            @toggleFilter="toggleFilter"
    />
    <div class="table-filter-wrap">
      <UnidentifiedPaymentsFilter
              :showFilter="showFilter"
              @close="closeFilter"
              :filterGetParams="filterGetParams"
              @changeFilter="data => $emit('changeFilter', data)"
              @resetFilter="$emit('resetFilter')"
      />
      <UnidentifiedPaymentsTable
              @showMore="$emit('showMore')"
              @resetFilter="$emit('resetFilter')"
              :countFilterParams="countFilterParams"
              @downloadFiles="type => $emit('downloadFiles', type)"
      />
    </div>
  </div>
</template>

<script>

  import UnidentifiedPaymentsHead from "./UnidentifiedPaymentsHead/UnidentifiedPaymentsHead";
  import UnidentifiedPaymentsTable from "./UnidentifiedPaymentsTable/UnidentifiedPaymentsTable";
  import UnidentifiedPaymentsFilter from "./UnidentifiedPaymentsFilter/UnidentifiedPaymentsFilter";


  export default {
    name: "UnidentifiedPaymentsTableUser",
    components: {
      UnidentifiedPaymentsHead,
      UnidentifiedPaymentsTable,
      UnidentifiedPaymentsFilter,
    },

    props: [
      'navTabs',
      'countFilterParams',
      'filterGetParams',
      'listPeriod',
    ],

    data() {
      return {
        showFilter: false,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

    },

  }
</script>

<style scoped>

</style>
