<template>
  <div class="fragment">

    <template v-if="$store.getters.getUnidentifiedPayments.length > 0 && $store.getters.getUnidentifiedPaymentsLoading !== true">
      <div class="custom-row unidentified-payments-table">
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--sm-50 custom-col--lg-50 custom-col--xl-33"
             v-for="(item, index) in $store.getters.getUnidentifiedPayments"
             :key="index">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__head">
                <div class="case-cart__title">
                  <div class="case-cart__title-caption"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit"
                          @click="editTranslate(['unidentifiedP_payPalTransaction'])"></span>
                    <template v-if="item.payment_system === 'paypal'">
                      {{$t('unidentifiedP_payPalTransaction.localization_value.value')}}
                    </template>
                  </div>
                  <span @click="openInfo(item.id)">
                  {{item.transaction_id}}
                </span>
                  <div class="case-cart__title-sub"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit"
                          @click="editTranslate(['unidentifiedP_payPalTransaction'])"></span>
                    {{$t('unidentifiedP_iD.localization_value.value')}}:
                    {{item.id}}
                  </div>
                </div>
              </div>
              <div class="case-cart__body">
                <div class="case-cart__row custom-row">
                  <div class="case-cart__col custom-col custom-col--50">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit"
                          @click="editTranslate(['unidentifiedP_created'])"></span>
                      {{$t('unidentifiedP_created.localization_value.value')}}
                    </div>
                    <div class="case-cart__content">
                      {{item.payment_transaction_detail.created_at | moment("DD MMM, YYYY")}}
                    </div>
                  </div>
                  <div class="case-cart__col custom-col custom-col--50">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit"
                          @click="editTranslate(['unidentifiedP_total'])"></span>
                      {{$t('unidentifiedP_total.localization_value.value')}}
                    </div>
                    <div class="case-cart__content">
                      <template v-if="item.status === CUSTOMERS_PAYMENTS_STATUSES.REFUND_TRANSACTION_STATUS">-</template>
                      ${{item.payment_total}}
                    </div>
                  </div>
                  <div v-if="isAdmin && navTabs && navTabs.archive && navTabs.archive.active" class="case-cart__col custom-col">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit"
                          @click="editTranslate(['common_comment'])"></span>
                      {{$t('common_comment.localization_value.value')}}
                    </div>
                    <div class="case-cart__content word-break">
                      <ValueHelper
                          :value="item.archived_comment"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit"
                          @click="editTranslate(['common_toArchive'])"></span>
                  <CaseCartButton
                      v-if="navTabs && navTabs.archive && !navTabs.archive.active"
                          class="secondary-brown"
                          :value="$t('common_toArchive.localization_value.value')"
                          :ico="true"
                          @click.native="archiveTransaction(item.id, index)"
                  >
                    <template slot="ico">
                      <DeleteIconBrown/>
                    </template>
                  </CaseCartButton>
                  <CaseCartButton
                      v-else
                      class="secondary-brown"
                      :value="$t('common_delete.localization_value.value')"
                      :ico="true"
                      @click.native="removeTransaction(item.id, index)"
                  >
                    <template slot="ico">
                      <DeleteIconBrown/>
                    </template>
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn ml-auto"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit"
                        @click="editTranslate(['unidentifiedP_addUser'])"></span>
                  <CaseCartButton
                          :value="$t('unidentifiedP_addUser.localization_value.value')"
                          :ico="true"
                          @click.native="addUser(item, index)"
                  >
                    <template slot="ico">
                      <PlusIconSVG/>
                    </template>
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="table-bottom-btn" >
        <div class="table-bottom-btn__inner">
          <ShowMore
                    v-if="$store.getters.getUnidentifiedPaymentsCommonList.next_page_url !== null && $store.getters.getUnidentifiedPayments.length > 0"
                    @click.native="$emit('showMore')"
                    :count="$store.getters.getUnidentifiedPaymentsCommonList.total - $store.getters.getUnidentifiedPaymentsForPage < $store.getters.getUnidentifiedPaymentsForPage ?
                  $store.getters.getUnidentifiedPaymentsCommonList.total - $store.getters.getUnidentifiedPaymentsForPage:
                  $store.getters.getUnidentifiedPaymentsForPage"
          />
          <ExportBtn
              v-if="!navTabs.archive.active"
              @downloadFiles="type => $emit('downloadFiles', type)"
                  class="table-bottom-btn__right"/>
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getUnidentifiedPaymentsLoading === false && $store.getters.getUnidentifiedPayments.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>

    <TransactionInfoAdmin
            v-if="isModalTransactionInfoAdmin"
            @close="changeTransactionInfoAdmin(false)"
    />


    <AddUserPopup
            v-if="isModalAddUserPopup"
            @close="changeAddUserPopup(false)"
            :itemTransaction="itemTransaction"
            :indexTransaction="indexTransaction"
    />
  </div>
</template>

<script>

  import DeleteIconBrown from "../../../../../../../../public/img/UI-group/delete-icon-brown.svg?inline"
  import CaseCartButton from "../../../../../../UI/buttons/CaseCartButton/CaseCartButton";
  import TransactionInfoAdmin from "../../../../popups/TransactionInfoAdmin/TransactionInfoAdmin";
  import AddUserPopup from "../../../../popups/AddUserPopup/AddUserPopup";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'
  import {CUSTOMERS_PAYMENTS_STATUSES} from "../../../../../../../staticData/staticVariables";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";

  export default {
    name: "UnidentifiedPaymentsTable",
    components: {
      ValueHelper,
      CaseCartButton,
      // EditIconWhite,
      DeleteIconBrown,
      TransactionInfoAdmin,
      // RequestPaymentPopup,
      AddUserPopup,
      PlusIconSVG,
      NoResult,
      ShowMore,
      ExportBtn,
    },

    props: ['countFilterParams', 'navTabs'],

    data() {
      return {
        CUSTOMERS_PAYMENTS_STATUSES: CUSTOMERS_PAYMENTS_STATUSES,

        isModalTransactionInfoAdmin: false,
        isModalRequestPaymentPopup: false,
        isModalAddUserPopup: false,

        itemTransaction: {},
        indexTransaction: NaN,
      }
    },

    methods: {

      openInfo(id) {
        this.$store.dispatch('getUnidentifiedPayments', id).then(() => {
          this.changeTransactionInfoAdmin(true)
        })
      },

      changeTransactionInfoAdmin(val) {
        this.isModalTransactionInfoAdmin = val
      },

      changeAddUserPopup(val) {
        this.isModalAddUserPopup = val
      },

      removeTransaction(id, index) {
        let text = {
          title: 'payments_deleteTransaction',
          txt: 'payments_deleteTransactionQuest',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.$store.dispatch('deleteUnidentifiedPayments', id).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              let payments = this.$store.getters.getUnidentifiedPayments
              payments.splice(index, 1)
              this.openNotify('success', 'common_notificationRecordDeleted')
            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      archiveTransaction(id, index) {
        let text = {
          title: 'payments_archiveTransaction',
          txt: 'payments_archiveTransactionQuest',
          yes: 'common_yes',
          no: 'common_no',
          commentTranslate: 'common_comment',
        }

        let confirm = (comment) => {

          let data = {
            comment: comment
          }

          this.$store.dispatch('archiveUnidentifiedPayments', {id, data}).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
              let payments = this.$store.getters.getUnidentifiedPayments
              payments.splice(index, 1)
              this.openNotify('success', 'common_notificationRecordAddedToArchive')
            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm, false, true)
      },

      addUser(item, index) {
        this.itemTransaction = item
        this.indexTransaction = index
        this.changeAddUserPopup(true)
      }
    }
  }
</script>

<style scoped lang="scss">
  .unidentified-payments-table {
    padding-top: 24px;

    .case-cart__title {
      font-size: 22px;
    }
  }
</style>
