var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"custom-popup request-payment-popup",on:{"close":function($event){return _vm.$emit('close')}}},[_c('template',{slot:"header"},[_c('div',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
            'unidentifiedP_requestPayment'
            ])}}}),_vm._v(" "+_vm._s(_vm.$t('unidentifiedP_requestPayment.localization_value.value'))+" ")])]),_c('template',{slot:"body"},[_c('div',{staticClass:"custom-popup__content",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
            'payments_transaction',
            'unidentifiedP_messageToAdmin',
            'common_cancel',
            'common_send',
            ])}}}),_c('div',{staticClass:"section-label large"},[_vm._v(" "+_vm._s(_vm.$t('payments_transaction.localization_value.value'))+": "+_vm._s(_vm.item.transaction_id)+" ")]),_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-col"},[_c('TextareaDefault',{class:{'ui-no-valid': _vm.validation.text},attrs:{"label":_vm.$t('unidentifiedP_messageToAdmin.localization_value.value'),"error":_vm.validation.text,"errorTxt":_vm.serverError ? _vm.validationTxt.text : _vm.$t(`${_vm.validationTranslate.text}.localization_value.value`),"type":'text'},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1),_c('div',{staticClass:"custom-col"},[_c('DropZone',{staticClass:"drop-zone-bg",class:{'ui-no-valid': _vm.validation.files},attrs:{"parentFiles":_vm.files,"maxSize":'3',"multiple":false,"error":_vm.validation.files,"errorTxt":_vm.serverError ? _vm.validationTxt.files : _vm.$t(`${_vm.validationTranslate.files}.localization_value.value`)},on:{"changeImg":_vm.changeImg}})],1)])])]),_c('template',{slot:"footer"},[_c('div',{staticClass:"import-transaction__btn d-flex align-items-center flex-wrap"},[_c('span',{staticClass:"site-link site-link--alt import-transaction__btn-i mt-3 mr-3 mb-3",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t('common_cancel.localization_value.value'))+" ")]),_c('MainButton',{staticClass:"import-transaction__btn-i",class:{'disabled-btn' : _vm.$store.getters.getUnidentifiedPaymentsBtn},attrs:{"value":_vm.$t('common_send.localization_value.value')},nativeOn:{"click":function($event){return _vm.requestPayment.apply(null, arguments)}}})],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }