<template>
  <modal
      @close="$emit('close')"
      class="transaction-info-modal transaction-info"
  >
    <template slot="header">
      <template v-if="payment = $store.getters.getUnidentifiedPaymentsItem">
        <span v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate([
            'cuspayments_transactions',
            'cuspayments_orderDetails',
            'cuspayments_lotInvoiceNumber',
            'cuspayments_trackingNumber',
            'cuspayments_customerInformation',
            'cuspayments_name',
            'common_phone',
            'common_email',
            'common_country',
            'common_state',
            'common_city',
            'common_address',
            'cuspayments_zipCode',
          ])"></span>
          {{$t('cuspayments_transactions.localization_value.value')}}:
          {{payment.transaction_id}}
        </span>
      </template>
    </template>
    <template slot="body">
      <div class="transaction-info__content" v-if="payment = $store.getters.getUnidentifiedPaymentsItem">
        <div class="transaction-info__name">
          {{ payment.payment_transaction_detail.created_at | moment("DD MMM, YYYY") }} {{$t('common_at.localization_value.value')}}
          {{ payment.payment_transaction_detail.created_at | moment("HH:mm") }}
        </div>
        <div class="transaction-info__status">
          <div class="transaction-info__status-txt font-weight-normal"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate([
            'common_Paid',
            'status_Returned',
            'common_InProgress',
            'common_Rejected',
            'common_statusNew',
          ])"></span>
            <div class="d-flex"
                 v-if="payment.status.toLowerCase() === CUSTOMERS_PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS">
              <StatusIcoBtn :type="'active'" class="mr-2 t-0"/>
              {{$t('common_Paid.localization_value.value')}}
            </div>
            <div class="d-flex"
                 v-if="payment.status.toLowerCase() === CUSTOMERS_PAYMENTS_STATUSES.REFUND_TRANSACTION_STATUS">
              <StatusIcoBtn :type="'active'" class="mr-2 t-0"/>
              {{$t('status_Returned.localization_value.value')}}
            </div>
            <div class="d-flex"
                 v-if="payment.status.toLowerCase() === CUSTOMERS_PAYMENTS_STATUSES.IN_PROGRESS_TRANSACTION_STATUS">
              <StatusIcoBtn :type="'dots'" class="mr-2 t-0"/>
              {{$t('common_InProgress.localization_value.value')}}
            </div>
            <div class="d-flex"
                 v-if="payment.status.toLowerCase() === CUSTOMERS_PAYMENTS_STATUSES.CANCELED_TRANSACTION_STATUS ||
                          payment.status === CUSTOMERS_PAYMENTS_STATUSES.ERROR_STATUS">
              <StatusIcoBtn :type="'rejected'" class="mr-2 t-0"/>
              {{$t('common_Rejected.localization_value.value')}}
            </div>
            <div class="d-flex"
                 v-if="payment.status.toLowerCase() === CUSTOMERS_PAYMENTS_STATUSES.ON_HOLD_STATUS">
              <StatusIcoBtn :type="'star'" class="mr-2 t-0"/>
              {{$t('common_statusNew.localization_value.value')}}
            </div>
          </div>
        </div>

        <div class="transaction-info__section">
          <div class="transaction-info__section-title">
            {{$t('cuspayments_orderDetails.localization_value.value')}}
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('cuspayments_lotInvoiceNumber.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                Lot:
                <template v-if="payment.payment_transaction_detail.l_number0">
                  <a target="_blank" :href="shopLink + payment.payment_transaction_detail.l_number0"
                     class="site-link site-link--light brown clear-after text-decoration-underline">
                    <ValueHelper
                        :value="payment.payment_transaction_detail.l_number0"
                    />
                  </a>
                </template>
                <ValueHelper
                    v-else
                    :value="payment"
                    :deep="'payment_transaction_detail.l_number0'"
                />
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('cuspayments_name.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="payment.payment_transaction_detail.payment_transaction_contact.full_name"/>
              </div>
            </div>
          </div>

        </div>

        <div class="transaction-info__section">
          <div class="transaction-info__section-title">
            <div class="transaction-info__status-ico mb-1">
              <StatusIcoBtn
                  :type="'delivered'"
              />
            </div>
            {{$t('cuspayments_customerInformation.localization_value.value')}}
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('cuspayments_name.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="payment.payment_transaction_detail.payment_transaction_contact.full_name"/>
              </div>
            </div>

            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('common_phone.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="payment.payment_transaction_detail.payment_transaction_contact.phone"/>
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('common_email.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        class="word-break"
                        :value="payment.payment_transaction_detail.payment_transaction_contact.email"/>
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('common_country.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="payment.payment_transaction_detail.payment_transaction_contact.country"/>
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('common_state.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="payment.payment_transaction_detail.payment_transaction_contact.state"/>
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('common_city.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="payment.payment_transaction_detail.payment_transaction_contact.city"/>
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('common_address.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="payment.payment_transaction_detail.payment_transaction_contact.address"/>
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('cuspayments_zipCode.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="payment.payment_transaction_detail.payment_transaction_contact.zip_code"/>
              </div>
            </div>
          </div>
        </div>


        <div class="transaction-info__section">
          <div class="transaction-info__section-title">
            {{$t('cuspayments_transactions.localization_value.value')}}
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('importO_transactionNumber.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="payment.transaction_id"/>
              </div>
            </div>
          </div>

          <div class="transaction-info__row">
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('cuspayments_totalAmount.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <template v-if="payment.status === CUSTOMERS_PAYMENTS_STATUSES.REFUND_TRANSACTION_STATUS">-</template>
                $
                <ValueHelper
                        :value="payment.payment_total"/>
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('cuspayments_SKLADUSAFee.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                $
                <ValueHelper
                        :value="payment.warehouse_commission_amount"/>
              </div>
            </div>

            <div class="transaction-info__col">
              <div class="transaction-info__label">
                <template v-if="payment.payment_system === 'braintree'">
                  {{$t('cuspayments_baintreeFee.localization_value.value')}}
                </template>
                <template v-else>
                  {{$t('cuspayments_paypalFee.localization_value.value')}}
                </template>
              </div>
              <div class="transaction-info__txt">
                $
                <ValueHelper
                        :value="payment.payment_commission"/>
              </div>
            </div>
            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('cuspayments_total.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                $
                <ValueHelper
                        :value="payment.user_amount"/>
              </div>
            </div>

            <div class="transaction-info__col">
              <div class="transaction-info__label">
                {{$t('cuspayments_tax.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                $
                <ValueHelper
                        :value="payment.payment_tax"/>
              </div>
            </div>

            <div class="transaction-info__col w-100">
              <div class="transaction-info__label">
                {{$t('cuspayments_items.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="payment.payment_transaction_detail.items"
                />
              </div>
            </div>

          </div>
        </div>



      </div>
    </template>
    <!--<template slot="footer">-->
      <!--<div class="transaction-info__btn">-->
        <!--<span class="site-link site-link&#45;&#45;alt brown mr-3 mt-3 mb-3">-->
          <!--Open Complain-->
        <!--</span>-->
        <!--<router-link :to="'/#'">-->
          <!--<MainButton-->
              <!--:value="'Refund Requests'"-->
              <!--class="wfc"-->
          <!--/>-->
        <!--</router-link>-->
      <!--</div>-->
    <!--</template>-->
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  // import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
  // import CheckIco from '../../../../../../public/img/common/check.svg?inline'
  import StatusIcoBtn from "../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import ValueHelper from "../../../../coreComponents/ValueHelper/ValueHelper";
  import {CUSTOMERS_PAYMENTS_STATUSES} from "../../../../../staticData/staticVariables";



  export default {
    name: "TransactionInfoAdmin",
    components: {
      Modal,
      // MainButton,
      // CheckIco,
      StatusIcoBtn,
      ValueHelper,
    },

    data() {
      return {
        CUSTOMERS_PAYMENTS_STATUSES: CUSTOMERS_PAYMENTS_STATUSES,
        shopLink: 'https://www.etsy.com/listing/',
      }
    }
  }

</script>

<style lang="scss">

</style>
