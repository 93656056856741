<template>
  <modal
          @close="$emit('close')"
          class="custom-popup medium-size"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate([
              'unidentifiedP_addUser'
              ])"></span>
        {{$t('unidentifiedP_addUser.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate([
              'unidentifiedP_viewHistory',
              'shop_user',
              'common_cancel',
              'common_date',
              ])"></span>
<!--        <div class="section-label large">-->
<!--          <span class="site-link site-link&#45;&#45;alt brown clear-after text-decoration-underline">-->
<!--            {{$t('unidentifiedP_viewHistory.localization_value.value')}}-->
<!--          </span>-->
<!--        </div>-->
        <div class="custom-row">
          <div class="custom-col custom-col--50 custom-col--sm-100">
            <DatePickerDefault
                    :label="$t('common_date.localization_value.value')"
                    v-model="date"
                    :disabled="true"
            >
              <template slot="body">
                <date-picker
                        v-model="date"
                        ref="datePicker"
                        valueType="format"
                        :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                        :format="'MM/DD/YY'"
                        :disabled="true"
                        :placeholder="date"
                ></date-picker>
              </template>
            </DatePickerDefault>
          </div>
          <div class="custom-col custom-col--50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['shop_user'])"></span>
            <SearchSelect
                    :options="options"
                    :label="$t('shop_user.localization_value.value')"
                    :functionSearch="functionSearch"
                    @change="changeUsersFilter"
                    :typeSelect="'users'"
                    :selected="userActive"
                    v-bind:class="{'ui-no-valid': validation.user}"
                    :error="validation.user"
                    :errorTxt="serverError ? validationTxt.user : $t(`${validationTranslate.user}.localization_value.value`)"
                    :userEmail="true"
            />
          </div>
          <div class="custom-col custom-col--50 custom-col--sm-100"
               v-if="showShopSelect"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['importO_downloadShops'])"></span>
            <DefaultSelect
                    :options="optionsShops"
                    :label="$t('importO_downloadShops.localization_value.value')"
                    :optionsLabel="'shop_name'"
                    @change="changeShop"
            />
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="import-transaction__btn d-flex align-items-center flex-wrap">
        <span
                class="site-link site-link--alt import-transaction__btn-i mt-3 mr-3 mb-3 fsz16"
                @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
                class="fsz16"
                :value="$t('unidentifiedP_addUser.localization_value.value')"
                @click.native="addUser"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
  import DatePickerDefault from "../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import SearchSelect from "../../../../UI/selectiones/SearchSelect/SearchSelect";
  import {usersFunc} from "../../../../../mixins/usersMixins/usersFunc";
  import {validation} from "../../../../../services/validation";
  import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";

  export default {
    name: "AddUserPopup",
    components: {
      Modal,
      MainButton,
      // DefaultInput,
      DatePickerDefault,
      DatePicker,
      SearchSelect,
      DefaultSelect,
    },

    mixins: [usersFunc],

    props: {
      itemTransaction: {
        type: Object,
      },
      indexTransaction: {
        type: Number,
      },
    },

    data() {
      return {
        number: '',
        date: this.$moment(this.itemTransaction.created_at).format('DD MMM, YYYY'),
        options: [],
        optionsShops: [],
        userActive: '',
        userSelect: {},
        shopSelect: {},
        showShopSelect: false,

        serverError: false,
        validation: {
          user: false,
        },

        validationTranslate: {
          user: 'default',
        },

        validationTxt: {
          user: false,
        },
      }
    },

    mounted() {

    },

    methods: {
      changeImg(files) {
        this.files = files
      },

      functionSearch(value, loading) {
        loading(true)
        this.getFilteredUsers(value, this, 'options', loading)
      },

      changeUsersFilter(value) {
        this.userActive = value.contacts[0].user_full_name
        this.userSelect = value

        this.$store.dispatch(`fetchShops`, `?filter[user]=${value.id}&filter[shopStatus]=valid`).then(() => {
          this.showShopSelect = true
          this.optionsShops = this.$store.getters.getShops
        })
      },

      changeShop(value) {
        this.shopSelect = value
      },

      addUser() {
        let validationItems = {
          user: this.userActive,
        }

        let validationOptions = {
          user: {
            type: [
              'empty',
            ]
          },
        };

        let validate = validation(validationItems, validationOptions);
        this.validation = validate.validation;
        this.validationTranslate = validate.validationError

        if(validate.isValidate) {
          let data = {
            id: this.itemTransaction.id,
            user_id: this.userSelect.id,
            shop_id: this.shopSelect.id,
          }
          this.$store.dispatch('addUserPayment', data).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

              this.$emit('close')
              this.openNotify('success', 'common_notificationRecordChanged')

              let payments = this.$store.getters.getUnidentifiedPayments
              payments.splice(this.indexTransaction, 1)

            } else {
              this.openNotify('error', 'common_notificationUndefinedError')
            }

          })
        }
      }
    }
  }

</script>

<style lang="scss">

</style>
