<template>
  <div class="fragment">
    <UnidentifiedPaymentsHead
        @toggleFilter="toggleFilter"
        :showFilter="showFilter"
        @changeTab="changeTab"
        :activeTab="activeTab"
        :navTabs="navTabs"
        :countFilterParams="countFilterParams"
        :listPeriod="listPeriod"
        :filterGetParams="filterGetParams"
        @changePeriod="(val) => $emit('changePeriod', val)"
    />

    <div class="table-filter-wrap">
      <UnidentifiedPaymentsFilter
          :showFilter="showFilter"
          @close="closeFilter"
          :filterGetParams="filterGetParams"
          @changeFilter="data => $emit('changeFilter', data)"
      />

      <UnidentifiedPaymentsTable
        @showMore="$emit('showMore')"
        @resetFilter="$emit('resetFilter')"
        :navTabs="navTabs"
        :countFilterParams="countFilterParams"
        @downloadFiles="type => $emit('downloadFiles', type)"
      />
    </div>
  </div>
</template>

<script>

  import UnidentifiedPaymentsHead from "./UnidentifiedPaymentsHead/UnidentifiedPaymentsHead";
  import UnidentifiedPaymentsTable from "./UnidentifiedPaymentsTable/UnidentifiedPaymentsTable";
  import UnidentifiedPaymentsFilter from "./UnidentifiedPaymentsFilter/UnidentifiedPaymentsFilter";


  export default {
    name: "UnidentifiedPaymentsTableAdmin",
    components: {
      UnidentifiedPaymentsHead,
      UnidentifiedPaymentsFilter,
      UnidentifiedPaymentsTable,
    },

    props: [
      'navTabs',
      'countFilterParams',
      'filterGetParams',
      'listPeriod',
    ],

    data(){
      return {
        showFilter: false,
        activeTab: 1,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      changeTab(id) {
        this.$emit('changeTab', id)
      },
    },
  }
</script>

<style scoped>

</style>
