<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['unidentifiedP_transactionNumber'])"></span>
        <DefaultInput
                :label="$t('unidentifiedP_transactionNumber.localization_value.value')"
                :type="'text'"
                v-model="trackingNumber"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['cuspayments_date'])"></span>
        <DatePickerDefault
                :label="$t('cuspayments_date.localization_value.value')"
                v-model="date"
        >
          <template slot="body">
            <date-picker
                    v-model="date"
                    ref="datePicker"
                    valueType="format"
                    range
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :format="'MM/DD/YY'"
                    :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['unidentifiedP_total'])"></span>
        <DefaultInput
                :label="$t('unidentifiedP_total.localization_value.value')"
                :type="'text'"
                v-model="total"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['unidentifiedP_total'])"></span>
        <DefaultInput
                :label="$t('unidentifiedP_buyersEmail.localization_value.value')"
                :type="'text'"
                v-model="buyersEmail"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['unidentifiedP_iD'])"></span>
        <DefaultInput
                :label="$t('unidentifiedP_iD.localization_value.value')"
                :type="'text'"
                v-model="id"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['unidentifiedP_priceFrom'])"></span>
        <DefaultInput
            :label="$t('unidentifiedP_priceFrom.localization_value.value')"
            :type="'text'"
            v-model="priceRange[0]"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['unidentifiedP_priceTo'])"></span>
        <DefaultInput
            :label="$t('unidentifiedP_priceTo.localization_value.value')"
            :type="'text'"
            v-model="priceRange[1]"
        />
      </div>
      <!--<div class="table-filter__item"-->
           <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--<span class="admin-edit"-->
              <!--@click="editTranslate(['unidentifiedP_items'])"></span>-->
        <!--<DefaultInput-->
                <!--:label="$t('unidentifiedP_items.localization_value.value')"-->
                <!--:type="'text'"-->
                <!--v-model="items"-->
        <!--/>-->
      <!--</div>-->
      <!--<div class="table-filter__item"-->
           <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--<span class="admin-edit"-->
              <!--@click="editTranslate(['unidentifiedP_invoiceNumber'])"></span>-->
        <!--<DefaultInput-->
                <!--:label="$t('unidentifiedP_invoiceNumber.localization_value.value')"-->
                <!--:type="'text'"-->
                <!--v-model="invoiceNumber"-->
        <!--/>-->
      <!--</div>-->
      <!--<div class="table-filter__item">-->
      <!--<DefaultInput-->
      <!--:label="'User'"-->
      <!--:type="'text'"-->
      <!--v-model="userId"-->
      <!--/>-->
      <!--</div>-->
    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')"
                  class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";

  export default {
    name: "UnidentifiedPaymentsFilter",
    components: {
      FilterBlock,
      MainButton,
      DatePickerDefault,
      DatePicker,
      DefaultInput,
    },

    props: [
      'showFilter',
      'filterGetParams',
    ],

    mixins: [filterFunc],

    data() {
      return {
        date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY') : '',
        type: this.filterGetParams.type ? this.filterGetParams.type : '',
        id: this.filterGetParams.id ? this.filterGetParams.id : '',
        trackingNumber: this.filterGetParams.trackingNumber ? this.filterGetParams.trackingNumber : '',
        total: this.filterGetParams.total ? this.filterGetParams.total : '',
        buyersEmail: this.filterGetParams.buyersEmail ? this.filterGetParams.buyersEmail : '',
        items: this.filterGetParams.items ? this.filterGetParams.items : '',
        invoiceNumber: this.filterGetParams.invoiceNumber ? this.filterGetParams.invoiceNumber : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',
        priceRange: this.filterGetParams.priceRange ? this.filterGetParams.priceRange.split(',') : ['', ''],

        filterItems: [
          'date',
          'type',
          'id',
          'trackingNumber',
          'total',
          'buyersEmail',
          'items',
          'invoiceNumber',
          'userId',
          'priceRange',
        ]
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
        this.type = newVal.type ? newVal.type : ''
        this.id = newVal.id ? newVal.id : ''
        this.trackingNumber = newVal.trackingNumber ? newVal.trackingNumber : ''
        this.total = newVal.total ? newVal.total : ''
        this.buyersEmail = newVal.buyersEmail ? newVal.buyersEmail : ''
        this.items = newVal.items ? newVal.items : ''
        this.invoiceNumber = newVal.invoiceNumber ? newVal.invoiceNumber : ''
        this.userId = newVal.userId ? newVal.userId : ''
        this.priceRange = newVal.priceRange ? newVal.priceRange.split(',') : ['', '']
      },
    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterItems)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterItems)
        data.date = this.changeDateParams(this, 'date')
        data.priceRange = this.changeRangeParams(this, 'priceRange')

        this.$emit('changeFilter', data)
      },
    },
  }
</script>

<style scoped>

</style>
