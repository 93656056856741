<template>
  <modal
      @close="$emit('close')"
      class="custom-popup request-payment-popup"
  >
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate([
              'unidentifiedP_requestPayment'
              ])"></span>
        {{$t('unidentifiedP_requestPayment.localization_value.value')}}
      </div>

    </template>
    <template slot="body">
      <div class="custom-popup__content"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate([
              'payments_transaction',
              'unidentifiedP_messageToAdmin',
              'common_cancel',
              'common_send',
              ])"></span>
        <div class="section-label large">
          {{$t('payments_transaction.localization_value.value')}}: {{item.transaction_id}}
        </div>
        <div class="custom-row">
          <div class=" custom-col">
            <TextareaDefault
                :label="$t('unidentifiedP_messageToAdmin.localization_value.value')"
                v-model="text"
                :error="validation.text"
                :errorTxt="serverError ? validationTxt.text : $t(`${validationTranslate.text}.localization_value.value`)"
                :type="'text'"
                v-bind:class="{'ui-no-valid': validation.text}"
            />
          </div>
          <div class=" custom-col">
            <DropZone class="drop-zone-bg"
                      :parentFiles="files"
                      @changeImg="changeImg"
                      :maxSize="'3'"
                      :multiple="false"
                      v-bind:class="{'ui-no-valid': validation.files}"
                      :error="validation.files"
                      :errorTxt="serverError ? validationTxt.files : $t(`${validationTranslate.files}.localization_value.value`)"
            />
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="import-transaction__btn d-flex align-items-center flex-wrap">
        <span
            class="site-link site-link--alt import-transaction__btn-i mt-3 mr-3 mb-3"
            @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
            :value="$t('common_send.localization_value.value')"
            class="import-transaction__btn-i"
            @click.native="requestPayment"
            v-bind:class="{'disabled-btn' : $store.getters.getUnidentifiedPaymentsBtn}"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
  import DropZone from "../../../../coreComponents/DropZone/DropZone";
  import TextareaDefault from "../../../../UI/textarea/TextareaDefault/TextareaDefault";
  import {validation} from "../../../../../services/validation";

  export default {
    name: "RequestPaymentPopup",
    components: {
      Modal,
      MainButton,
      DropZone,
      TextareaDefault,
    },

    props: {
      item: {
        type: Object,
      }
    },

    data(){
      return {
        files: [],
        text: '',

        serverError: false,

        validation: {
          text: false,
          files: false,
        },

        validationTranslate: {
          text: 'default',
          files: 'default',
        },

        validationTxt: {
          text: false,
          files: false,
        },

        maxSizeCheck: false,
      }
    },

    mounted() {

    },

    methods: {
      changeImg(files, maxSizeCheck) {
        this.maxSizeCheck = maxSizeCheck
        this.files = files
      },

      requestPayment(e) {
        e.preventDefault();

        let validationItems = {
          text: this.text,
          files: this.files,
        }

        let validationOptions = {
          text: {
            type: [
              'empty',
            ]
          },
          files: {
            type: [
              'file',
            ]
          },
        };

        let validate = validation(validationItems, validationOptions);
        this.validation = validate.validation;
        this.validationTranslate = validate.validationError

        if(validate.isValidate && !this.maxSizeCheck) {
          this.serverError = false
          let formDataArray = []

          // this.files.map((item) => {
          let reader = new FormData();
          reader.append("id", this.item.id);
          reader.append("file", this.files[0].file);
          reader.append("message", this.text);
          formDataArray = reader
          // })

          this.$store.dispatch('requestPayment', formDataArray).then(response => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

              this.$emit('close')
              this.openNotify('success', 'common_notificationRecordSended')

            } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
              let errors = response.response.data.errors;

              errors.message ? (this.validation.text = true, this.validationTxt.text = errors.message[0]) : false;
            } else {
              this.openNotify('error', 'common_notificationUndefinedError')
            }

          })
        }
      }
    }
  }

</script>

<style lang="scss">
  .request-payment-popup{

    .modal-component__inner{
      max-width: 685px;
    }
  }


</style>
