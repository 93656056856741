<template>
  <div class="fragment">
    <template
            v-if="$store.getters.getUnidentifiedPayments.length > 0 && $store.getters.getUnidentifiedPaymentsLoading !== true">
      <div class="custom-row unidentified-payments-table">
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--sm-50 custom-col--xl-33"
             v-for="(item, index) in $store.getters.getUnidentifiedPayments"
             :key="index">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__head">
                <div class="case-cart__title">
                  <div class="case-cart__title-caption"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit"
                          @click="editTranslate(['unidentifiedP_payPalTransaction'])"></span>
                    <template v-if="item.payment_system === 'paypal'">
                      {{$t('unidentifiedP_payPalTransaction.localization_value.value')}}
                    </template>
                  </div>
                  <span @click="openInfo(item.id)">
                  {{item.transaction_id}}
                </span>
                  <div class="case-cart__title-sub"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit"
                          @click="editTranslate(['unidentifiedP_iD'])"></span>
                    {{$t('unidentifiedP_iD.localization_value.value')}}: {{item.id}}
                  </div>
                </div>
              </div>
              <div class="case-cart__body">
                <div class="case-cart__row custom-row">
                  <div class="case-cart__col custom-col custom-col--50">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit"
                          @click="editTranslate(['unidentifiedP_created'])"></span>
                      {{$t('unidentifiedP_created.localization_value.value')}}
                    </div>
                    <div class="case-cart__content">
                      {{item.payment_transaction_detail.created_at | moment("DD MMM, YYYY")}}
                    </div>
                  </div>
                  <div class="case-cart__col custom-col custom-col--50">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <span class="admin-edit"
                          @click="editTranslate(['unidentifiedP_total'])"></span>
                      {{$t('unidentifiedP_total.localization_value.value')}}
                    </div>
                    <div class="case-cart__content">
                      ${{item.payment_total}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit"
                        @click="editTranslate(['unidentifiedP_sendRequest'])"></span>
                  <CaseCartButton
                          :value="$t('unidentifiedP_sendRequest.localization_value.value')"
                          :ico="true"
                          @click.native="itemRequest(item)"
                  >
                    <template slot="ico">
                      <EditIconWhite/>
                    </template>
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="table-bottom-btn" >
        <div class="table-bottom-btn__inner">
          <ShowMore
                  v-if="$store.getters.getUnidentifiedPaymentsCommonList.next_page_url !== null && $store.getters.getUnidentifiedPayments.length > 0"
                  @click.native="$emit('showMore')"
                  :count="$store.getters.getUnidentifiedPaymentsCommonList.total - $store.getters.getUnidentifiedPaymentsForPage < $store.getters.getUnidentifiedPaymentsForPage ?
                  $store.getters.getUnidentifiedPaymentsCommonList.total - $store.getters.getUnidentifiedPaymentsForPage:
                  $store.getters.getUnidentifiedPaymentsForPage"
          />
<!--          <ExportBtn-->
<!--                  @click.native="$emit('downloadFiles')"-->
<!--                  class="table-bottom-btn__right"/>-->
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getUnidentifiedPaymentsLoading === false && $store.getters.getUnidentifiedPayments.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>

    <TransactionInfo
            v-if="isModalTransactionInfo"
            @close="changeTransactionInfo(false)"
    />

    <RequestPaymentPopup
            v-if="isModalRequestPaymentPopup"
            @close="changeRequestPaymentPopup(false)"
            :item="selectItemRequest"
    />
  </div>
</template>

<script>

  import EditIconWhite from "../../../../../../../../public/img/UI-group/edit-icon-white_.svg?inline"
  import CaseCartButton from "../../../../../../UI/buttons/CaseCartButton/CaseCartButton";
  import TransactionInfo from "../../../../popups/TransactionInfo/TransactionInfo";
  import RequestPaymentPopup from "../../../../popups/RequestPaymentPopup/RequestPaymentPopup";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  // import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'

  export default {
    name: "UnidentifiedPaymentsTable",
    components: {
      CaseCartButton,
      EditIconWhite,
      TransactionInfo,
      RequestPaymentPopup,
      NoResult,
      ShowMore,
      // ExportBtn,
    },

    props: {
      countFilterParams: Number,
    },

    data() {
      return {
        isModalTransactionInfo: false,
        isModalRequestPaymentPopup: false,

        selectItemRequest: {},
      }
    },

    methods: {
      openInfo(id) {
        this.$store.dispatch('getUnidentifiedPayments', id).then(() => {
          this.changeTransactionInfo(true)
        })
      },

      changeTransactionInfo(val) {
        this.isModalTransactionInfo = val
      },

      changeRequestPaymentPopup(val) {
        this.isModalRequestPaymentPopup = val
      },

      itemRequest(item) {
        this.selectItemRequest = item
        this.changeRequestPaymentPopup(true)
      }
    }
  }
</script>

<style scoped>
  .unidentified-payments-table {
    padding-top: 24px;
  }
</style>
